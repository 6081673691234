import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid,
  Radio
} from '@material-ui/core'
import { parseJson } from '../../../../components/formatters/parseJson'
import { NoPaymentMethodDisclaimer } from '../../../fragments/no-payment-method'
import React, { Fragment, useState } from 'react'
import { CreditCard } from '../../../creditCard'
import { TokenexASCIIDisplay } from '../../../tokenexASCIIDisplay'
import { TokenexSSNDisplay } from '../../../tokenexSSNDisplay'
import Activity from '../activity'
import Modal from '../modal'
import PackageInfo from '../packageInfo'
import UserInfo from '../userInfo'
import { DEPOSIT_AMOUNT } from './constants'

const EquipmentOptions = ({
  classes,
  record: { installation: { modem_choice = '' } = {} } = {}
}) => (
  <Fragment>
    <div>
      <FormControlLabel
        control={
          <Radio
            checked={modem_choice.toLowerCase() === 'internetrental'}
            classes={{ disabled: classes.disabled }}
          />
        }
        label="xFi Gateway ($15.00 / month)"
        disabled
        classes={{ label: classes.label }}
      />
      <p className={classes.optionSubtext}>
        Customer understands that the price may increase to up to $25/mo after
        the first 12-24 months
      </p>
    </div>
    <div>
      <FormControlLabel
        control={
          <Radio
            checked={modem_choice.toLowerCase() === 'internetmodemowned'}
            classes={{ disabled: classes.disabled }}
          />
        }
        label="I’ll use my own"
        disabled
        classes={{ label: classes.label }}
      />
    </div>
  </Fragment>
)

const IdentityVerification = ({ classes, dobToken, ssnToken, record }) => {
  const creditCheckMethod =
    record?.credit_check?.credit_check_method?.toLowerCase()

  const priorAddress = record?.credit_check?.prior_address

  const [openDobModal, setOpenDobModal] = useState(false)
  const handleOpenDobModal = () => setOpenDobModal(true)
  const handleCloseDobModal = () => setOpenDobModal(false)

  const [openSsnModal, setOpenSsnModal] = useState(false)
  const handleOpenSsnModal = () => setOpenSsnModal(true)
  const handleCloseSsnModal = () => setOpenSsnModal(false)

  return (
    <Fragment>
      <div>
        {priorAddress && (
          <>
            <h3>Move From Address</h3>
            <p>
              Uncheck Same as Service Address and in CCUI, enter the
              customer&apos;s current address.
            </p>
            <div>
              <div>
                <b>Street:</b> {priorAddress?.street}
              </div>
              <div>
                <b>Apartment:</b> {priorAddress?.unit}
              </div>
              <div>
                <b>City:</b> {priorAddress?.city}
              </div>
              <div>
                <b>State:</b> {priorAddress?.state}
              </div>
              <div>
                <b>Zip Code:</b>{' '}
                {priorAddress?.postalCode || priorAddress?.postal_code}
              </div>
            </div>
          </>
        )}

        <h3>Payment type</h3>
        <div>
          <FormControlLabel
            control={
              <Radio
                checked={creditCheckMethod === 'autopay'}
                classes={{ disabled: classes.disabled }}
              />
            }
            label="Auto pay"
            labelPlacement="end"
            classes={{ label: classes.label }}
            disabled
          />
        </div>
        <div>
          <FormControlLabel
            control={
              <Radio
                checked={creditCheckMethod === 'deposit'}
                classes={{ disabled: classes.disabled }}
              />
            }
            label="Deposit"
            labelPlacement="end"
            classes={{ label: classes.label }}
            disabled
          />
        </div>
        <div>
          <FormControlLabel
            control={
              <Radio
                checked={creditCheckMethod === 'authorize-credit-check'}
                classes={{ disabled: classes.disabled }}
              />
            }
            label="Authorize Credit Check"
            labelPlacement="end"
            classes={{ label: classes.label }}
            disabled
          />
        </div>
      </div>
      {ssnToken && (
        <div style={{ marginTop: 20 }}>
          <Button variant="contained" onClick={handleOpenSsnModal}>
            Show Full SSN Info
          </Button>
          <Modal
            open={openSsnModal}
            onClose={handleCloseSsnModal}
            title="Full SSN Info"
          >
            <div>
              <TokenexSSNDisplay token={ssnToken} />
            </div>
          </Modal>
        </div>
      )}
      <h3>Date of Birth</h3>
      <div style={{ marginTop: 20 }}>
        <Button variant="contained" onClick={handleOpenDobModal}>
          Show Date of Birth
        </Button>
        <Modal
          open={openDobModal}
          onClose={handleCloseDobModal}
          title="Date of Birth"
        >
          <div>
            <TokenexASCIIDisplay token={dobToken} />
          </div>
        </Modal>
      </div>
    </Fragment>
  )
}

const InstallationInformation = ({ classes }) => (
  <Fragment>
    <div>
      <h3>Choose type of installation</h3>
      <FormControlLabel
        control={
          <Checkbox checked={true} classes={{ disabled: classes.disabled }} />
        }
        label="Self-Install"
        labelPlacement="end"
        classes={{ label: classes.label }}
        disabled
      />
    </div>
    <div>
      <h3>Shipping type</h3>
      <p>Getting started kit, standard shipping fee up to $39.99</p>
      <p className={classes.subtext}>
        Proceed with ordering processing even if actual shipping cost is less
        than $39.99
      </p>
    </div>
  </Fragment>
)

const BillingInfo = ({ price: { items = {} } = {}, record, classes }) => {
  const creditCheckMethod =
    record?.credit_check?.credit_check_method?.toLowerCase()
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  if (!record?.payment?.credit_card) {
    return <NoPaymentMethodDisclaimer />
  }

  const paymentAmount =
    creditCheckMethod === 'autopay'
      ? items.autopay.price.toFixed(2)
      : creditCheckMethod === 'deposit'
      ? DEPOSIT_AMOUNT.toFixed(2)
      : ''
  const paymentKind =
    creditCheckMethod === 'autopay'
      ? 'pre-payment'
      : creditCheckMethod === 'deposit'
      ? 'deposit'
      : ''
  const paymentHeader = paymentKind
    ? `${paymentKind[0].toUpperCase()}${paymentKind.slice(1)}`
    : ''

  const {
    billing_address: billingAddress = {},
    expiration_date: expirationDate,
    first_name: firstName,
    last_name: lastName,
    pci = {}
  } = record?.payment?.credit_card || {}
  const creditCardProps = {
    ...pci,
    expirationDate,
    firstName,
    lastName
  }

  return (
    <Fragment>
      <div>
        <h3>{paymentHeader} Amount</h3>
        <div>${paymentAmount}</div>
      </div>
      <p className={classes.subtext}>
        Proceed with order processing if actual {paymentKind} amount is&nbsp;$
        {paymentAmount} or less.
      </p>
      <div>
        <h3>Credit Card Info</h3>
        <div>
          <Button variant="contained" onClick={handleOpen}>
            Show Credit Card Info
          </Button>
          <Modal open={open} onClose={handleClose} title="Credit Card Info">
            <CreditCard
              {...creditCardProps}
              allowTokenexDetokenizer={true}
              allowTokenDisplay={true}
            />
          </Modal>
        </div>
      </div>
      <div>
        <h3>Billing Address</h3>
        <div>
          <b>Street:</b> {billingAddress?.street}
        </div>
        <div>
          <b>Apartment:</b> {billingAddress?.unit}
        </div>
        <div>
          <b>City:</b> {billingAddress?.city}
        </div>
        <div>
          <b>State:</b> {billingAddress?.state}
        </div>
        <div>
          <b>Zip Code:</b>{' '}
          {billingAddress?.postalCode || billingAddress?.postal_code}
        </div>
      </div>
    </Fragment>
  )
}

const AdditionalOptions = ({ classes, record: { opt_ins = {} } = {} }) => (
  <Fragment>
    <div>
      <h3>Allow Updater and Xfinity to send me SMS updates about my order.</h3>
      <div>
        <FormControlLabel
          control={
            <Radio
              checked={opt_ins.sms_opt_in}
              classes={{ disabled: classes.disabled }}
            />
          }
          label="Yes, I consent"
          labelPlacement="end"
          disabled
          classes={{ label: classes.label }}
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Radio
              checked={!opt_ins.sms_opt_in}
              classes={{ disabled: classes.disabled }}
            />
          }
          label="No, I do not consent"
          labelPlacement="end"
          disabled
          classes={{ label: classes.label }}
        />
      </div>

      <h3>Enroll in Paperless Billing (EcoBill)</h3>
      <div>
        <FormControlLabel
          control={
            <Radio
              checked={opt_ins.sms_opt_in}
              classes={{ disabled: classes.disabled }}
            />
          }
          label="Yes, I consent"
          labelPlacement="end"
          disabled
          classes={{ label: classes.label }}
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Radio
              checked={!opt_ins.sms_opt_in}
              classes={{ disabled: classes.disabled }}
            />
          }
          label="No, I do not consent"
          labelPlacement="end"
          disabled
          classes={{ label: classes.label }}
        />
      </div>
    </div>
  </Fragment>
)

export const OrderView = ({ record, classes }) => {
  const ssnToken = parseJson(record?.credit_check?.ssn?.token)?.token
  const dobToken = parseJson(record?.credit_check?.date_of_birth?.token)?.token

  return (
    <Fragment>
      <Grid item xs={6}>
        <UserInfo record={record} />
      </Grid>
      <Grid item xs={6}>
        <PackageInfo record={record} />
      </Grid>
      <Grid item xs={12}>
        <Card style={{ marginTop: 20 }}>
          <CardHeader title="Equipment Options" />
          <CardContent>
            <EquipmentOptions record={record} classes={classes} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card style={{ marginTop: 20 }}>
          <CardHeader title="Identity Verification" />
          <CardContent>
            <IdentityVerification
              classes={classes}
              dobToken={dobToken}
              ssnToken={ssnToken}
              record={record}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card style={{ marginTop: 20 }}>
          <CardHeader title="Installation Information" />
          <CardContent>
            <InstallationInformation record={record} classes={classes} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card style={{ marginTop: 20 }}>
          <CardHeader title="Billing info" />
          <CardContent>
            <BillingInfo
              classes={classes}
              record={record}
              price={record.price}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card style={{ marginTop: 20 }}>
          <CardHeader title="Additional Options" />
          <CardContent>
            <AdditionalOptions record={record} classes={classes} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Activity record={record} classes={classes} />
      </Grid>
    </Fragment>
  )
}
